import type { GetServerSideProps } from 'next';
import Image from 'next/legacy/image';
import dynamic from 'next/dynamic';
import { Container, Typography, Box, styled, useTheme } from '@mui/material';
import FullWidthSection from ':src/components/FullWidthSection';
import MoGrid from ':src/components/MoGrid';
import PricingTable from ':src/components/PricingTable';
import CoursesSummary from ':src/components/CoursesSummary';
import CourseCard from ':src/components/CourseCard';
import MoLink from ':src/components/MoLink';
import { addApolloState, initializeApollo } from ':src/apollo/apolloClient';
import { ViewerQuery } from ':src/apollo/auth/__generated__/ViewerQuery';
import { viewerQuery } from ':src/apollo/auth/queries';
import useViewer from ':src/hooks/useViewer';
import CourseCarousel from ':src/components/CourseCarousel';
import {
  CoursesQuery,
  CoursesQueryVariables,
} from ':src/apollo/courses/__generated__/CoursesQuery';
import { continuingEducationSummaryQuery, coursesQuery } from ':src/apollo/courses/queries';
import { CourseTypeEnum } from ':src/lookup';
import { Course } from ':src/types';
import {
  ContinuingEducationSummaryQuery,
  ContinuingEducationSummaryQuery_continuingEducationSummary,
} from ':src/apollo/courses/__generated__/ContinuingEducationSummaryQuery';
import Head from ':src/components/Head';
import MoButtonLink from ':src/components/MoButtonLink';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import MembershipCTA from ':src/components/MembershipCTA';

const WelcomeModal = dynamic(() => import(':src/components/WelcomeModal'));
const SignupSuccess = dynamic(() => import(':src/components/SignupSuccess'), { ssr: false });

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const client = initializeApollo(null, ctx);

  await client.query<ViewerQuery>({
    query: viewerQuery,
  });

  const newCoursesPromise = client.query<CoursesQuery, CoursesQueryVariables>({
    query: coursesQuery,
    variables: {
      limit: 3,
      offset: 0,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: false,
    },
  });

  const flagshipCoursesPromise = client.query<CoursesQuery, CoursesQueryVariables>({
    query: coursesQuery,
    variables: {
      limit: 3,
      offset: 0,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: true,
    },
  });

  const allCoursesPromise = client.query<CoursesQuery, CoursesQueryVariables>({
    query: coursesQuery,
    variables: {
      limit: 6,
      offset: 3,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: false,
    },
  });

  const continuingEducationSummaryPromise = client.query<ContinuingEducationSummaryQuery>({
    query: continuingEducationSummaryQuery,
  });

  const [
    newCoursesResponse,
    flagshipCoursesResponse,
    allCoursesResponse,
    continuingEducationResponse,
  ] = await Promise.all([
    newCoursesPromise,
    flagshipCoursesPromise,
    allCoursesPromise,
    continuingEducationSummaryPromise,
  ]);

  return addApolloState(client, {
    props: {
      newCourses: newCoursesResponse.data.courses?.result,
      flagshipCourses: flagshipCoursesResponse.data.courses?.result,
      allCourses: allCoursesResponse.data.courses?.result,
      continuingEducationSummary: continuingEducationResponse.data.continuingEducationSummary,
    },
  });
};

const HeroBanner = styled('div')(({ theme }) => ({
  marginTop: -32,
  backgroundImage: `url(${process.env.NEXT_PUBLIC_IMGIX_HOSTNAME}/static/hero-image.png?auto=format)`,
  backgroundPosition: 'right -200px top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.up('sm')]: {
    marginTop: -64,
  },

  [theme.breakpoints.up('md')]: {
    backgroundSize: 'contain',
    backgroundPosition: 'right -150px center',
  },

  [theme.breakpoints.up('lg')]: {
    backgroundPosition: 'right center',
  },
}));

const HeroHeader = styled(Typography)(({ theme }) => ({
  fontSize: 30,

  [theme.breakpoints.up('sm')]: {
    fontSize: 42,
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
}));

const PhoneImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },

  '& > *': {
    position: 'absolute !important',
    margin: 'auto !important',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}));

export type HomeProps = {
  newCourses: Course[];
  flagshipCourses: Course[];
  allCourses: Course[];
  continuingEducationSummary: ContinuingEducationSummaryQuery_continuingEducationSummary;
};

export default function Home({
  newCourses,
  flagshipCourses,
  allCourses,
  continuingEducationSummary,
}: HomeProps) {
  const { viewer } = useViewer();
  const theme = useTheme();
  const { query } = useRouter();

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);
  const [amountPaid, setAmountPaid] = useState<null | number>(null);

  useEffect(() => {
    const storedAmountPaid = window.sessionStorage?.getItem?.('amountPaid');
    setAmountPaid(storedAmountPaid != null ? Number(storedAmountPaid) : null);
    window.sessionStorage?.removeItem?.('amountPaid');
  }, []);

  const metaTitle = 'Motivo Learning | Continuing education through meaningful dialogue';

  return (
    <>
      <Head>
        <title key="title">{metaTitle}</title>
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta name="twitter:title" content={metaTitle} key="twitterTitle" />
      </Head>
      {viewer?.id ? (
        <Container>
          <Box mb={6}>
            <Box mb={6}>
              {!viewer.featureFlags?.includes('umpr--bypass-subscription') && <MembershipCTA />}
              <CoursesSummary summary={continuingEducationSummary} />
            </Box>
            {continuingEducationSummary.inProgressOnDemandCourse && (
              <>
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="h3">Return to Learning, {viewer.firstName}</Typography>
                  <MoLink href="/profile/courses" underline="always">
                    View my courses
                  </MoLink>
                </Box>
                <CourseCard course={continuingEducationSummary.inProgressOnDemandCourse} />
              </>
            )}
          </Box>
        </Container>
      ) : (
        <HeroBanner>
          <Container>
            <Box py={{ xs: 8, md: 16 }} width={{ xs: '80%', sm: '60%' }}>
              <HeroHeader variant="h1" mb={1}>
                Continuing education through meaningful dialogue
              </HeroHeader>
              <Typography display={{ xs: 'none', sm: 'block' }} maxWidth={400}>
                Motivo Learning is a collection of courses that provide clinicians the tools to
                thrive in today’s landscape.
              </Typography>
              <Box mt={2}>
                <MoButtonLink href="/signup">Start learning</MoButtonLink>
              </Box>
            </Box>
          </Container>
        </HeroBanner>
      )}

      <Box mb={6}>
        <FullWidthSection>
          <Typography variant="h3" mb={2}>
            New courses
          </Typography>
          <CourseCarousel courses={newCourses} />
        </FullWidthSection>
      </Box>

      <Container>
        <Box mb={8}>
          <Typography variant="h3" mb={2}>
            Motivo picks
          </Typography>
          <CourseCarousel courses={flagshipCourses} />
        </Box>

        <Box mb={8}>
          <Typography variant="h3" mb={2}>
            All courses
          </Typography>
          <MoGrid container>
            {allCourses.map((course) => (
              <MoGrid item sm={6} md={4} key={course.id}>
                <CourseCard course={course} isVertical />
              </MoGrid>
            ))}
          </MoGrid>
          <Box mt={4} mb={8} textAlign="center">
            <MoLink href="/courses" variant="body3">
              See more
            </MoLink>
          </Box>
        </Box>
      </Container>

      {!viewer?.id && (
        <Box mb={{ xs: -4, sm: -8 }}>
          <FullWidthSection backgroundColor={theme.palette.green[50]}>
            <MoGrid container>
              <MoGrid item sm={6}>
                <PhoneImageWrapper>
                  <Image
                    src="static/iphone-screenshot.png?auto=format"
                    layout="fixed"
                    objectFit="cover"
                    width={240}
                    height={492}
                    alt="iPhone screenshot of website"
                  />
                </PhoneImageWrapper>
              </MoGrid>
              <MoGrid item sm={6}>
                <Box
                  maxWidth={{ sm: 380 }}
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <PricingTable />
                </Box>
              </MoGrid>
            </MoGrid>
          </FullWidthSection>
        </Box>
      )}

      {!viewer?.id && query.welcome && (
        <WelcomeModal open={isWelcomeModalOpen} onClose={() => setIsWelcomeModalOpen(false)} />
      )}

      {viewer?.id && amountPaid != null && <SignupSuccess amountPaidInCentsOverride={amountPaid} />}
    </>
  );
}
