import { ReactNode } from 'react';
import { Box, Typography, Card, CardContent, useTheme, useMediaQuery } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';
import { ContinuingEducationSummaryMyCoursesQuery_continuingEducationSummary } from ':src/apollo/courses/__generated__/ContinuingEducationSummaryMyCoursesQuery';
import { pluralize } from ':src/utils/string';
import MoGrid from './MoGrid';
import CTA from './CTA';

type InfoCardProps = {
  label: string;
  value: string | number;
};

const ResponsiveWrapper = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const InfoCard = ({ label, value }: InfoCardProps) => (
  <ResponsiveWrapper>
    <Typography variant="h3" component="div" fontSize={14} mb={0.5}>
      {label}
    </Typography>
    <Typography variant="h3" component="div" color="primary.main" fontFamily="proxima-nova">
      {value}
    </Typography>
  </ResponsiveWrapper>
);

export type CoursesSummaryProps = {
  summary: ContinuingEducationSummaryMyCoursesQuery_continuingEducationSummary;
};

type UpcomingCourse = {
  id: string;
  scheduledFor: string;
  slug: string;
  name: string;
};

function UpcomingCourseCard({ course }: { course: UpcomingCourse }) {
  const minutesAway = dayjs(course.scheduledFor).diff(dayjs(), 'minute');
  return (
    <CTA
      text={`Your course, ${course.name}, is starting ${
        minutesAway === 0 ? 'right now' : `in ${minutesAway} ${pluralize('minute', minutesAway)}`
      }`}
      buttonText="Attend course"
      buttonHref={`/courses/${course.slug}`}
      image={<ErrorIcon />}
    />
  );
}

export default function CoursesSummary({
  summary: {
    creditsCompletedCount,
    coursesCompletedCount,
    coursesInProgressCount,
    upcomingLiveCourse,
  },
}: CoursesSummaryProps) {
  return (
    <>
      {upcomingLiveCourse && (
        <Box mb={4}>
          <UpcomingCourseCard course={upcomingLiveCourse} />
        </Box>
      )}
      <MoGrid container>
        <MoGrid item xs={4} sm={3}>
          <InfoCard label="Credits completed" value={`${creditsCompletedCount}`} />
        </MoGrid>
        <MoGrid item xs={4} sm={3}>
          <InfoCard label="Courses in progress" value={coursesInProgressCount} />
        </MoGrid>
        <MoGrid item xs={4} sm={3}>
          <InfoCard label="Courses completed" value={coursesCompletedCount} />
        </MoGrid>
      </MoGrid>
    </>
  );
}
