import { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import useViewer from ':src/hooks/useViewer';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import CTA from './CTA';

const MembershipModal = dynamic(() => import(':src/components/MembershipModal'));

export default function MembershipCTA() {
  const { viewer } = useViewer();
  const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
  const [shouldRenderCTA, setShouldRenderCTA] = useState(false);
  const [shouldRenderModal, setShouldRenderModal] = useState(false);

  useEffect(() => {
    setShouldRenderCTA(Boolean(!viewer?.canAccessMotivoLearning));
  }, [viewer]);

  useEffect(() => {
    setShouldRenderModal(Boolean(!viewer?.canAccessMotivoLearning));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {shouldRenderCTA && (
        <Box mb={3}>
          <CTA
            text="You do not currently have an active membership. Let’s activate your plan."
            image={
              <Image src="/assets/membership-cta.svg" height={80} width={70} alt="" unoptimized />
            }
            buttonText="Activate plan"
            buttonOnClick={() => setIsMembershipModalOpen(true)}
          />
        </Box>
      )}
      {shouldRenderModal && (
        <MembershipModal
          title="We’re excited to have you!"
          open={isMembershipModalOpen}
          onClose={() => setIsMembershipModalOpen(false)}
          onSuccess={() => setIsMembershipModalOpen(false)}
        />
      )}
    </>
  );
}
