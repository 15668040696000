import { ReactNode } from 'react';
import { Box, Container, useTheme } from '@mui/material';

export type FullWidthSectionProps = {
  backgroundColor?: string;
  textColor?: string;
  children: ReactNode;
};

export default function FullWidthSection({
  backgroundColor,
  textColor,
  children,
}: FullWidthSectionProps) {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: backgroundColor || theme.palette.grey[200] }}>
      <Container>
        <Box py={6} color={textColor}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}
